<template>
  <div class="view view--light">
    <p>{{ docType }}</p>
  </div>
</template>

<script>
export default {
  name: 'ReportIndex',

  computed: {
    docType() {
      const { docType } = this.$route.query;
      return docType;
    }
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {}
  }
};
</script>
